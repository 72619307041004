<template>
  <div class="grid grid-cols-1 md:grid-cols-4 gap-6 mt-8">
    <home-blocks-wrapper
      id="testimonial"
      :title="LL.the_user()"
      :highlight-title="LL.testimonial()"
      class="md:col-span-3 mb-0"
    >
      <UCarousel
        v-slot="{ item }"
        ref="carouselRef"
        :items="testimonials"
        :ui="{
          item: 'basis-full md:basis-1/1 lg:basis-1/2',
          indicators: {
            wrapper: 'relative flex items-center justify-center inset-x-0 mt-4',
            inactive: 'bg-gray-200 dark:bg-gray-700 mix-blend-normal',
          },
        }"
        indicators
        class="rounded-lg overflow-hidden"
      >
        <HomeBlocksTestimonialItem
          :name="item.name"
          :role="item.role"
          :avatar-url="item.avatarUrl"
          :script="item.script"
        />
      </UCarousel>
    </home-blocks-wrapper>
    <OurChannels />
  </div>
</template>

<script setup lang="ts">
import { typesafeI18n } from "~/locales/i18n-vue";
import OurChannels from "./OurChannels.vue";

const { LL } = typesafeI18n();
const carouselRef = ref();

onMounted(() => {
  setInterval(() => {
    if (!carouselRef.value) return;

    if (carouselRef.value.page === carouselRef.value.pages) {
      return carouselRef.value.select(0);
    }

    carouselRef.value.next();
  }, 5000);
});

const testimonials = computed(() => [
  {
    name: "Hiep Tran Quang",
    avatarUrl: "/images/home/blocks/user1.png",
    role: "Software engineer",
    script: LL.value.testimonials_1(),
  },
  {
    name: "Nhat Do Cuong",
    avatarUrl: "/images/home/blocks/user2.png",
    role: "Software engineer",
    script: LL.value.testimonials_2(),
  },
  {
    name: "Linh Phan",
    avatarUrl: "/images/home/blocks/user3.png",
    role: "Software engineer",
    script: LL.value.testimonials_3(),
  },
  {
    name: "Khanh Pham",
    avatarUrl: "/images/home/blocks/user4.png",
    role: "Software engineer",
    script: LL.value.testimonials_4(),
  },
  {
    name: "Lam Tung Nguyen",
    avatarUrl: "/images/home/blocks/user5.png",
    role: "Software engineer",
    script: LL.value.testimonials_5(),
  },
  {
    name: "Tri Hoang Duc",
    avatarUrl: "/images/home/blocks/user6.png",
    role: "Software engineer",
    script: LL.value.testimonials_6(),
  },
]);
</script>
